import React from 'react';
import { makeStyles, Typography } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import SearchCriteria from '../SearchCriteria/SearchCriteria';

const useStyles = makeStyles((theme) => ({
  heading: {
    color: theme.palette.coreBlue,
    fontSize: '20px',
    lineHeight: 1.6,
  },
  newSearchButton: {
    marginBottom: '17px',
  },
  buttons: {
    display: 'block',
    paddingLeft: 0,
    color: theme.palette.coreRed,
    fontWeight: 'bold',
    fontSize: '24px',
    textDecorationLine: 'underline',
    border: '0',
    textAlign: 'left',
    backgroundColor: 'transparent',
  },
}));

const NoResults = ({ location }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2" align="justify" className={classes.heading}>
        Sorry we can&apos;t seem to find what you are looking for - try being
        more specific with your Keywords and include National Services in your
        search to show you helplines and online support available wherever you
        are in the UK
      </Typography>
      <SearchCriteria location={location} />
      <button
        type="button"
        className={`${classes.buttons} ${classes.newSearchButton}`}
        onClick={() => navigate('/')}
      >
        Start a new search
      </button>
    </>
  );
};

NoResults.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default NoResults;
