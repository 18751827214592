import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/';
import NoResults from './NoResults/NoResults';
import Results from './Results/Results';
import LoadingResults from './LoadingResults/LoadingResults';

const useStyles = makeStyles((theme) => ({
  results_wrapper: {
    backgroundColor: 'white',
    zIndex: 1,
    position: 'relative',
    padding: '30px 17px',
    fontFamily: 'Open Sans',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px 40px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '35px 200px',
    },
  },
}));

const SearchResults = ({
  isLoaded,
  results,
  location,
  pointer,
  setPointer,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.results_wrapper}>
      {isLoaded &&
        (results.length > 0 ? (
          <Results
            location={location}
            results={results}
            pointer={pointer}
            setPointer={setPointer}
          />
        ) : (
          <NoResults location={location} />
        ))}
      {!isLoaded && <LoadingResults />}
    </div>
  );
};

SearchResults.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  results: PropTypes.arrayOf(PropTypes.string).isRequired,
  pointer: PropTypes.number.isRequired,
  setPointer: PropTypes.func.isRequired,
};

export default SearchResults;
