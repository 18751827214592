/* eslint-disable camelcase */
import Fuse from 'fuse.js';
import * as he from 'he';
import bodyParser from '../../pages/search/bodyParserNationalResources';
/**
 * Returns the distance in miles between 2 points
 * @param {Object}
 * @return {number}
 */
export const calcDistance = (
  { latitude: lat1, longitude: long1 },
  { latitude: lat2, longitude: long2 },
) => {
  if (!lat1 || !lat2) return null;

  const earthRadius = 3959;

  const radLat1 = lat1 * (Math.PI / 180);
  const radLat2 = lat2 * (Math.PI / 180);

  const deltaRadLat = (lat2 - lat1) * (Math.PI / 180);
  const deltaRadLong = (long2 - long1) * (Math.PI / 180);

  const a =
    Math.sin(deltaRadLat / 2) ** 2 +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.sin(deltaRadLong / 2) ** 2;

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
};

const sortByDistance = (result) =>
  result.sort((a, b) => {
    if (a.distance > b.distance) return 1;
    if (a.distance < b.distance) return -1;
    return 0;
  });

const mergeSearchResults = (results1, results2) => {
  const initialResults = [...results1, ...results2];
  return initialResults;
};

export const getInstitute = (filteredResults, searchKey, includeNational) => {
  const options = {
    threshold: 0.2,
    useExtendedSearch: true,
    ignoreLocation: true,
    keys: ['keywords', 'title', 'body', 'what', 'region', 'organisation'],
  };

  const fuse = new Fuse(filteredResults, options);

  let results;

  if (searchKey) {
    const search = fuse.search({
      $and: [
        {
          $or: [
            { organisation: searchKey },
            { keywords: searchKey },
            { title: searchKey },
            { body: searchKey },
            { what: searchKey },
          ],
        },
        { region: includeNational ? 'National' : '!National' },
      ],
    });
    results = search.map((result) => result.item);
  } else {
    const search = fuse.search('National');
    results = search.map((result) => result.item);
  }
  return results;
};

export const mapWithDistance = (allWordpressPost, myLatitude, myLongitude) =>
  allWordpressPost.edges.map(
    ({
      node: {
        wordpress_id,
        slug,
        acf: {
          what,
          latitude,
          longitude,
          body,
          service,
          city,
          region,
          keywords,
          organisation,
        },
        title,
      },
    }) => ({
      post_id: wordpress_id,
      slug,
      title: he.decode(title),
      body,
      distance: calcDistance(
        { latitude: myLatitude, longitude: myLongitude },
        { latitude, longitude },
      ),
      latitude,
      longitude,
      what: bodyParser(what),
      service,
      city,
      region,
      keywords: keywords
        ? keywords.split(',').map((keyword) => keyword.trim())
        : [],
      organisation,
    }),
  );
export const mapWithTitleSort = (allWordpressPost) =>
  allWordpressPost.edges
    .map(
      ({
        node: {
          wordpress_id,
          slug,
          acf: {
            what,
            latitude,
            longitude,
            service,
            city,
            region,
            keywords,
            organisation,
          },
          title,
        },
      }) => ({
        post_id: wordpress_id,
        slug,
        title: he.decode(title),
        what: bodyParser(what),
        latitude,
        longitude,
        service,
        city,
        region,
        keywords: keywords
          ? keywords.split(',').map((keyword) => keyword.trim())
          : [],
        organisation,
      }),
    )
    .sort((a, b) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });

export const getSearchResults = async (
  allWordpressPost,
  geoData,
  searchKeywords,
  postcode,
  searchRegion,
  serviceType,
  radius,
  searchCity,
  includeNational,
) => {
  // applying filters region, service, keywords (if existing)
  // if lat long exists => order by distance
  // else order by title
  let results = [];

  if (geoData.results.length === 0) {
    return results;
  }

  const resultsSortedByTitle = mapWithTitleSort(allWordpressPost);

  if (
    (postcode || searchCity) &&
    (geoData.results[0].types.includes('postal_code') ||
      geoData.results[0].types.includes('locality') ||
      geoData.results[0].types.includes('sublocality') ||
      geoData.results[0].types.includes('political'))
  ) {
    const {
      lat: myLatitude,
      lng: myLongitude,
    } = geoData.results[0].geometry.location;
    results = mapWithDistance(allWordpressPost, myLatitude, myLongitude)
      .filter((value) => value.distance <= radius)
      .sort((a, b) => {
        if (a.distance > b.distance) return 1;
        if (a.distance < b.distance) return -1;
        return 0;
      });
  } else {
    results = resultsSortedByTitle;
  }

  const allInstitutions = resultsSortedByTitle;

  const filteredLocalResults = results.filter(
    (careProvider) =>
      (searchRegion
        ? careProvider.region.toLowerCase() === searchRegion.toLowerCase()
        : true) &&
      (serviceType
        ? careProvider.service.some((service) => serviceType.includes(service))
        : true),
  );

  const sortedFilteredLocalResults = sortByDistance(filteredLocalResults);
  const nationalSearch = getInstitute(allInstitutions, searchKeywords, true);
  const localSearch = getInstitute(filteredLocalResults, searchKeywords, false);
  const sortedLocalSearch = sortByDistance(localSearch);

  const mergedResults = mergeSearchResults(localSearch, nationalSearch);

  if (
    includeNational &&
    !postcode &&
    !searchRegion &&
    !searchCity &&
    searchKeywords
  ) {
    return nationalSearch;
  }
  if (searchKeywords && includeNational) {
    return mergedResults;
  }
  if (
    !searchKeywords &&
    (postcode || searchCity || searchRegion) &&
    includeNational
  ) {
    return mergeSearchResults(sortedFilteredLocalResults, nationalSearch);
  }
  if (!searchKeywords && includeNational) {
    return nationalSearch;
  }
  if (searchKeywords && !includeNational) {
    return sortedLocalSearch;
  }
  return sortedFilteredLocalResults;
};

export const getGeoData = async (postcode = '', city) => {
  let baseUrl = '';
  if (city) {
    baseUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${city}&region=gb&components=country:GB&key=${process.env.GATSBY_GOOGLE_MAPS_API}`;
  } else {
    baseUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${postcode.replace(
      ' ',
      '',
    )}&region=gb&components=country:GB&key=${
      process.env.GATSBY_GOOGLE_MAPS_API
    }`;
  }
  return fetch(baseUrl).then((response) =>
    response
      .json()
      .then((data) => ({
        data,
      }))
      .then((res) => res.data),
  );
};

export const invalidSearch = (state) => Object.keys(state).length === 1;
