import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Select,
  ListItemText,
  MenuItem,
  Box,
} from '@material-ui/core/';
import { navigate } from 'gatsby';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import * as queryString from 'query-string';
import {
  DEFAULT_RESULTS_PER_PAGE,
  RADIUS_OPTIONS,
  RESULTS_PER_PAGE_OPTIONS,
} from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  searchKeyItem: {
    paddingRight: '5px',
  },
  searchTerms: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    marginBottom: '30px',
  },
  controls_container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    marginTop: '10px',
  },
  control: {
    [theme.breakpoints.up('md')]: {
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));

const printParams = (key, value) => {
  if (key === 'postcode') {
    return value.toUpperCase();
  }
  if (Array.isArray(value)) {
    return value.reduce((pre, next) => `${pre}, ${next}`);
  }
  return value;
};
/**
 * This component renders the users seach criteria as a list to be displayed above search results
 * @param {object} location
 */
const SearchCriteria = ({
  location,
  resultsPerPage,
  setResultsPerPage,
  totalResults,
  setPointer,
}) => {
  const classes = useStyles();
  const queryParamsObj = queryString.parse(location.search);
  const locationState = queryParamsObj || {};
  const {
    keywords,
    postcode,
    city,
    radius,
    region,
    serviceType,
    includeNational,
  } = locationState;

  const handleNewRadiusSearch = (event) => {
    const newRadius = event.target.value;
    const searchParams = {
      keywords,
      postcode,
      radius: parseInt(newRadius),
      city,
      region,
      serviceType,
      includeNational,
    };
    const params = Object.keys(searchParams)
      .filter((key) => searchParams[key])
      .map((key) => `${key}=${encodeURIComponent(searchParams[key])}`)
      .join('&');
    sessionStorage.setItem('searchPagePointer', '0');
    navigate(`/search?${params}`);
  };

  const handleResultsPerPageChange = (event) => {
    setPointer(0);
    setResultsPerPage(parseInt(event.target.value));
  };

  return (
    <div className={classes.searchTerms}>
      {Object.keys(locationState).map((searchKey) =>
        locationState[searchKey] &&
        searchKey !== 'key' &&
        searchKey !== 'radius' &&
        searchKey !== 'includeNational' ? (
          <span className={classes.searchKeyItem} key={searchKey}>
            <b>
              {searchKey === 'serviceType'
                ? `Service Type: `
                : `${searchKey.slice(0, 1).toUpperCase()}${searchKey.slice(
                    1,
                  )}: `}
            </b>
            {printParams(searchKey, locationState[searchKey])}
          </span>
        ) : null,
      )}
      <Box className={classes.controls_container}>
        {locationState.radius ? (
          <div className={classes.control}>
            <b>Radius: </b>
            <Select
              value={radius}
              data-testid="radiusDropdown"
              onChange={handleNewRadiusSearch}
              variant="filled"
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              className={classes.searchKeyItem}
              SelectDisplayProps={{
                'data-testid': 'radiusDropdownSelect',
              }}
            >
              {RADIUS_OPTIONS.map((option) => (
                <MenuItem
                  key={`${option}`}
                  value={`${option}`}
                  data-testid={`${option}`}
                >
                  <ListItemText primary={`${option} miles`} />
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : null}
        {totalResults > DEFAULT_RESULTS_PER_PAGE ? (
          <div className={classes.control}>
            <b>Results per page: </b>
            <Select
              value={resultsPerPage}
              data-testid="resultsDropdown"
              onChange={handleResultsPerPageChange}
              variant="filled"
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              SelectDisplayProps={{
                'data-testid': 'resultsDropdownSelect',
              }}
            >
              {RESULTS_PER_PAGE_OPTIONS.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  data-testid={`${option} results`}
                >
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : null}
      </Box>
    </div>
  );
};

SearchCriteria.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
  setPointer: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  // storageName: PropTypes.string.isRequired,
};

export default SearchCriteria;
