import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, Paper } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginBottom: '25px',
    borderRadius: '10px',
    backgroundColor: '#F9F9F9',
    border: '0.5px solid #E2E2E2',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  text_skeleton: {
    height: '67px',
  },
  result_skeleton: {
    height: '266px',
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      height: '163px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '143px',
    },
  },
  skeleton_line: {
    marginBottom: '10px',
    '&:first-of-type': {
      width: '150px',
      [theme.breakpoints.up('sm')]: {
        width: '320px',
      },
    },
    height: '26px',
  },
}));

const LoadingResults = () => {
  const classes = useStyles();
  return (
    <>
      <Paper
        className={`${classes.skeleton} ${classes.text_skeleton}`}
        data-testid="loadingCards"
      />
      <Paper className={`${classes.skeleton} ${classes.result_skeleton}`}>
        <Skeleton variant="text" className={classes.skeleton_line} />
        <Skeleton variant="text" className={classes.skeleton_line} />
        <Skeleton variant="text" className={classes.skeleton_line} />
      </Paper>
      <Paper className={`${classes.skeleton} ${classes.result_skeleton}`} />
      <Paper className={`${classes.skeleton} ${classes.result_skeleton}`} />
      <Paper className={`${classes.skeleton} ${classes.result_skeleton}`} />
      <Paper className={`${classes.skeleton} ${classes.result_skeleton}`} />
    </>
  );
};

export default LoadingResults;
