import React, { useEffect, useRef } from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import scrollTo from 'gatsby-plugin-smoothscroll';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#545F6A',
    height: '50px',
    minWidth: '0px',
    '&:hover': {
      backgroundColor: '#545F6A',
    },
  },
  disabled: {
    '&$disabled': {
      backgroundColor: 'rgba(84, 95, 106, 0.3)',
    },
  },
  pages: {
    paddingRight: '15px',
    paddingLeft: '29px',
  },
}));

const PageControls = ({
  totalResults,
  pointer,
  setPointer,
  storageName,
  resultsPerPage,
}) => {
  const classes = useStyles();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      sessionStorage.setItem(storageName, pointer.toString());
      scrollTo('#pageHeading');
    } else {
      isMounted.current = true;
    }
  }, [pointer]);

  return (
    <Grid
      data-testid="pageControls"
      id="pageControls"
      justify="center"
      alignItems="center"
      item
      container
    >
      <Box width={50} height={50}>
        <Button
          data-testid="previousButton"
          id="previousButton"
          classes={{ root: classes.root, disabled: classes.disabled }}
          variant="contained"
          disabled={pointer / resultsPerPage < 1}
          onClick={() => {
            setPointer(pointer - resultsPerPage);
          }}
        >
          <KeyboardArrowLeftRounded />
        </Button>
      </Box>

      <Typography
        data-testid="pageDisplay"
        id="pageDisplay"
        className={classes.pages}
        variant="body1"
      >
        {'Page '}
        <b>{`${pointer / resultsPerPage + 1} `}</b>
        {`of ${Math.ceil(totalResults / resultsPerPage)}`}
      </Typography>

      <Box width={50} height={50}>
        <Button
          id="nextButton"
          data-testid="nextButton"
          classes={{ root: classes.root, disabled: classes.disabled }}
          variant="contained"
          disabled={pointer + resultsPerPage >= totalResults}
          onClick={() => {
            scrollTo('#pageHeading');
            setPointer(pointer + resultsPerPage);
          }}
        >
          <KeyboardArrowRightRounded />
        </Button>
      </Box>
    </Grid>
  );
};

PageControls.propTypes = {
  totalResults: PropTypes.number.isRequired,
  pointer: PropTypes.number.isRequired,
  setPointer: PropTypes.func.isRequired,
  storageName: PropTypes.string.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
};

export default PageControls;
