import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  expansion_panel: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktop_search: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  filterToggle: {
    paddingRight: '30px',
    paddingLeft: '22px',
    '& p': {
      margin: 0,
    },
  },
  filters: {
    padding: 0,
  },
  header: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      height: '610px',
      width: '100%',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingBottom: '4px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '200px',
      paddingRight: '200px',
      paddingBottom: '20px',
    },
  },
  subheader: {
    backgroundColor: '#293745',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '30px',
    minWidth: '380px',
    padding: '20px 20px 10px 20px',
    color: '#f5f5f5',
    [theme.breakpoints.up('lg')]: {
      padding: '20px 36px 10px 27px',
    },
    '& h1': {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('md')]: {
      '& h1': {
        fontSize: '32px',
      },
    },
    heading: {
      paddingLeft: '20px',
      paddingTop: '20px',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '200px',
      },
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    search: {
      [theme.breakpoints.up('md')]: {
        maxHeight: '545px',
        minWidth: '420px',
        maxWidth: '420px',
      },
    },
  },
  headerOne: {
    marginBottom: '12px',
  },
}));
