import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import RedButton from '../../RedButton/RedButton';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    border: '0.5px solid #E2E2E2',
    borderRadius: '10px',
    marginBottom: '25px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  body: {
    marginBottom: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  distance: {
    marginBottom: 0,
    marginLeft: '8px',
  },
  content: {
    alignItems: 'center',
    '&:first-of-type': {
      borderBottom: '1px solid #DBDBDB',
    },
    '&:last-of-type': {
      paddingBottom: '16px',
      color: '#545F6A',
      display: 'flex',
    },
  },
  locationContainer: {
    marginLeft: 'auto',
    '&& button': {
      padding: '0 15px',
    },
    display: 'flex',
  },
  pin: {
    color: theme.palette.coreBlue,
    height: 27,
    width: 24,
  },
}));

const ResultCard = ({
  location,
  title,
  what,
  distance,
  slug,
  totalResults,
  pointer,
}) => {
  const classes = useStyles();

  const sendToGtm = () => {
    const queryParamsObj = queryString.parse(location.search);
    queryParamsObj.includeNational = queryParamsObj.includeNational === 'true';
    if (queryParamsObj.serviceType) {
      queryParamsObj.serviceType = queryParamsObj.serviceType.split(',');
    }
    const { keywords, postcode, city, region, radius, includeNational } =
      queryParamsObj || {};
    const tagManagerArgs = {
      event: 'viewCareProvider',
      searchCareProvider: title,
      searchCareProviderDistance:
        distance === -1 || distance === null || distance === undefined
          ? -1
          : distance.toFixed(2),
      searchCareProviderSlug: slug,
      searchAppearancePage: pointer,
      searchPostcode: postcode,
      searchRadius: radius,
      searchRegion: region,
      searchCity: city,
      searchKeywords: keywords,
      searchServiceType: queryParamsObj.serviceType,
      searchNationalOrgs: includeNational,
      searchPostcodeArea:
        postcode !== undefined ? postcode.match(/\D+/)[0] : undefined,
      searchCount: totalResults,
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(tagManagerArgs);
  };

  const navigateToProvider = () => {
    sendToGtm();
    navigate(`/care-provider/${slug}`);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography
          variant="body1"
          className={classes.title}
          onClick={() => navigateToProvider()}
        >
          {title}
        </Typography>
        <Typography variant="body1" className={classes.body}>
          {what}
        </Typography>
      </CardContent>
      <CardContent className={classes.content}>
        <RedButton id="moreDetailsButton" onClick={() => navigateToProvider()}>
          More Details
        </RedButton>

        <Box
          className={`${classes.locationContainer} ${classes.boxBreakPoint}`}
        >
          <LocationOnIcon className={classes.pin} />
          <Typography className={classes.distance}>
            {distance === -1 || distance === null || distance === undefined
              ? 'UK'
              : `${distance.toFixed(1)} miles`}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResultCard;

ResultCard.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
  what: PropTypes.string.isRequired,
  distance: PropTypes.number,
  slug: PropTypes.string.isRequired,
  totalResults: PropTypes.number.isRequired,
  pointer: PropTypes.number.isRequired,
};

ResultCard.defaultProps = {
  distance: -1,
};
