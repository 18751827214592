/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ResultCard from '../ResultCard/ResultCard';
import PageControls from '../../PageControls/PageControls';
import { DEFAULT_RESULTS_PER_PAGE } from '../../../utils/constants';
import SearchCriteria from '../SearchCriteria/SearchCriteria';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '10px',
    color: theme.palette.coreBlue,
    fontSize: '30px',
  },
  messagePrompt: {
    marginBottom: '10px',
    color: theme.palette.coreBlue,
    fontSize: '20px',
    lineHeight: 1.6,
  },
  resultCardItem: {
    width: '100%',
  },
}));

const Results = ({ location, results, pointer, setPointer }) => {
  const [resultsPerPage, setResultsPerPage] = useState(
    DEFAULT_RESULTS_PER_PAGE,
  );
  const classes = useStyles();
  const { length } = results;
  // used for direct navigation to /search
  if (isNaN(pointer)) {
    pointer = 0;
  }
  return (
    <>
      <Typography
        variant="h2"
        className={classes.heading}
        id="pageHeading"
        data-testid="pageHeading"
      >
        {`${length} results found`}
      </Typography>
      <SearchCriteria
        location={location}
        resultsPerPage={resultsPerPage}
        setResultsPerPage={setResultsPerPage}
        totalResults={length}
        setPointer={setPointer}
      />
      <Grid container>
        {results.slice(pointer, pointer + resultsPerPage).map((result) => (
          <Grid item key={result.post_id} className={classes.resultCardItem}>
            <ResultCard
              key={result.post_id}
              {...result}
              location={location}
              totalResults={length}
              pointer={pointer / resultsPerPage + 1}
            />
          </Grid>
        ))}
        {length > resultsPerPage && (
          <PageControls
            id="pageControls"
            pointer={pointer}
            setPointer={setPointer}
            resultsPerPage={resultsPerPage}
            totalResults={length}
            storageName="searchPagePointer"
          />
        )}
      </Grid>
      <Typography
        variant="h2"
        align="justify"
        className={classes.messagePrompt}
      >
        If you&apos;ve not found what you are looking for locally, try including
        National Services in your search to show you helplines and online
        support available wherever you are in the UK
      </Typography>
    </>
  );
};

Results.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  pointer: PropTypes.number.isRequired,
  setPointer: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(Object).isRequired,
};

export default Results;
