/* eslint-disable no-console  */

import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { ExpandMore } from '@material-ui/icons';
import * as queryString from 'query-string';
import Layout from '../../components/layout';
import SearchResults from '../../components/SearchResults/SearchResults';
import Map from '../../components/map/map';
import useStyles from '../../styles/searchPageStyles';
import SearchMenu from '../../components/SearchMenu/SearchMenu';
import SEO from '../../components/seo';
import { getSearchResults, getGeoData } from '../../utils/searchUtils/index';
import { SERVICE_OPTIONS } from '../../utils/constants';

const SearchPage = ({ location }) => {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [geoData, setGeoData] = useState('');
  const [results, setResults] = useState([]);
  const [pointer, setPointer] = useState(0);
  const { allWordpressPost } = useStaticQuery(graphql`
    query AllServicesQuery {
      allWordpressPost {
        edges {
          node {
            wordpress_id
            slug
            acf {
              organisation
              what
              latitude
              longitude
              service
              city
              region
              keywords
            }
            title
          }
        }
      }
    }
  `);
  // location.state may be empty if user navigates to /search directly hence "|| {}""
  const queryParamsObj = queryString.parse(location.search);
  queryParamsObj.includeNational = queryParamsObj.includeNational === 'true';
  if (queryParamsObj.serviceType) {
    queryParamsObj.serviceType = queryParamsObj.serviceType
      .split(',')
      .filter((type) => SERVICE_OPTIONS.includes(type));
  }
  if (queryParamsObj.radius) {
    queryParamsObj.radius = Number(queryParamsObj.radius);
  }
  const { keywords, postcode, city, region, radius, includeNational } =
    queryParamsObj || {};

  const { serviceType } = queryParamsObj;

  useEffect(() => {
    const fetchGeoData = async () => {
      setGeoData(await getGeoData(postcode, city));
    };
    fetchGeoData();
  }, [postcode, city]);

  useEffect(() => {
    setIsLoaded(false);
    if (geoData !== '') {
      const filteredResults = async () => {
        setResults(
          await getSearchResults(
            allWordpressPost,
            geoData,
            keywords,
            postcode,
            region,
            serviceType,
            radius,
            city,
            includeNational,
          ),
        );
      };
      if (location && !queryParamsObj) {
        setResults([]);
      } else {
        filteredResults();
      }
      setIsLoaded(true);
      setPointer(parseInt(sessionStorage.getItem('searchPagePointer')));
      if (!sessionStorage.getItem('searchPagePointer')) {
        sessionStorage.setItem('searchPagePointer', '0');
      }
    }
  }, [geoData, keywords, region, serviceType, radius, includeNational]);

  useEffect(() => {
    if (isLoaded) {
      const event =
        results.length > 0 ? 'findServicesResult' : 'findServicesNoResult';
      const tagManagerArgs = {
        event,
        searchPostcode: postcode,
        searchRadius: radius,
        searchRegion: region,
        searchCity: city,
        searchKeywords: keywords,
        searchServiceType: queryParamsObj.serviceType,
        searchNationalOrgs: includeNational,
        searchPostcodeArea:
          postcode !== undefined ? postcode.match(/\D+/)[0] : undefined,
        searchCount: results.length,
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(tagManagerArgs);
    }
  }, [results]);

  return (
    <Layout>
      <SEO title="Search" />
      <div className={classes.header}>
        <div>
          <div className={classes.subheader}>
            <Typography variant="h4" className={classes.headerOne}>
              Find cancer support services near you
            </Typography>
          </div>
          <ExpansionPanel
            expanded={filterOpen}
            className={classes.expansion_panel}
          >
            <ExpansionPanelSummary
              className={classes.filterToggle}
              onClick={() => setFilterOpen(!filterOpen)}
              expandIcon={<ExpandMore />}
            >
              <Typography variant="body1">
                {filterOpen ? 'HIDE FILTERS' : 'SHOW FILTERS'}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.filters}>
              <SearchMenu location={location} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div className={classes.desktop_search}>
            <SearchMenu location={location} />
          </div>
        </div>
        <Map
          // currentRoute='\search'
          // isPostCode={postcode !== ''}
          zoomLevel={12}
          center={
            isLoaded && geoData.results[0] && (postcode || city)
              ? {
                  lat: parseFloat(geoData.results[0].geometry.location.lat),
                  lng: parseFloat(geoData.results[0].geometry.location.lng),
                }
              : {}
          }
          postMarkers={
            isLoaded && results.length > 0
              ? results
                  .filter(
                    (result) =>
                      result.longitude !== '0' &&
                      result.latitude !== '0' &&
                      result.region !== 'National',
                  )
                  .map((result) => ({
                    lat: parseFloat(result.latitude),
                    lng: parseFloat(result.longitude),
                    title: result.title,
                  }))
              : []
          }
        />
      </div>
      <SearchResults
        location={location}
        // setFilterOpen={setFilterOpen}
        isLoaded={isLoaded}
        results={results}
        pointer={pointer}
        setPointer={setPointer}
      />
    </Layout>
  );
};
SearchPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
export default SearchPage;
